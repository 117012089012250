import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { Box } from 'grommet';
import { ResponsiveContext } from 'grommet/contexts';
import Layout from '../layout/primary';
import htmlSerializer from '../prismic/htmlSerializer';

const StyledBox = styled(Box)`
  margin: auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px;
  overflow: hidden;
  @media screen and (min-width: 769px) {
    padding: 90px;
  }
`;

const StyledBanner = styled(Box)`
  margin: auto;
  width: 100%;
  background-color: rgb(203, 54, 29);
  text-align: center;
  color: white;
  font-size: 36px;
  font-family: Roboto, sans-serif;
  font-weight: 400 !important;
  padding: 90px 10px;
  line-height: 1.5;
  @media screen and (max-width: 769px) {
    font-size: 20px;
  }
`;

export const query = graphql`
  {
    prismic {
      legal(lang: "en-us", uid: "terms") {
        main_text
        meta_title
        meta_description
      }
    }
  }
`;

export default function({ data }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        return (
          <Layout>
            <StyledBanner>
              <h1>A Warning about Recruiting Scams</h1>
            </StyledBanner>
            <StyledBox size={size}>
              <h1
                style={{
                  textAlign: 'center',
                  fontWeight: '300',
                  fontFamily: 'Roboto, sans-serif',
                  lineHeight: 1.5,
                }}
              >
                Thank you for your interest in POWER Engineers.
              </h1>
              <p>
                The only way to apply for a position at POWER Engineers is through our secure
                corporate website.
                <br />
                <br />
                You will, however, see our positions posted on various job opportunity platforms on
                the internet. Please be cautious and wary of recruitment scams where bad actors
                pretend to represent prominent companies.
                <br />
                <br />
                For example, unsuspecting applicants may be invited to participate in bogus
                interviews, asked to fill out fabricated employment applications and, on occasion,
                have even been issued fake offer letters, all with the ultimate goal of trying to
                entice victims to pay money or{' '}
                <strong>divulge sensitive personal information</strong>.
              </p>
              <h2>Be Aware of These Red Flags:</h2>
              <ul>
                <li>
                  Unsolicited communications from an individual or websites with which you are
                  unfamiliar, or whose domain name is inconsistent with the actual business.
                </li>
                <li>
                  Unsolicited communication where the recruiter claims to have seen your resume on a
                  site with which you are not familiar.
                </li>
                <li>Correspondence from free e-mail accounts like Yahoo, Hotmail or Gmail. </li>
                <li>
                  Communications that do not include information about a specific job opening (or
                  the job description is vague) and/or extend a job offer without a personal
                  interview.
                </li>
                <li>
                  Utilization of video chat rooms (e.g. Google Hangouts) to conduct interviews.
                </li>
                <li>Requests for monetary payments at any point in the hiring process. </li>
                <li>
                  Job opportunities that come from people you do not know and appear “too good to be
                  true.”
                </li>
                <li>
                  During the application phase, recruiters ask for sensitive personal data or
                  information, such as your driver’s license, national identification number, date
                  of birth, social security number, national insurance number, bank account
                  information, passport information or any number appearing on identity documents.
                </li>
              </ul>
              <h2>POWER’s Hiring Process:</h2>
              <ul>
                <li>
                  The <strong>only</strong> way to apply for a position at POWER Engineers is
                  through our secure careers website.
                </li>
                <li>
                  Email communications from POWER Engineers will be through our @powereng.com domain
                  name.
                </li>
                <li>
                  Our recruiters <strong>may</strong> initiate communication with you via LinkedIn,
                  however, we will <strong>never</strong> solicit candidates through a{' '}
                  <strong>non-POWER email address or phone numbers</strong>.
                </li>
                <li>
                  Our recruiters will <strong>never</strong> extend a job offer without an
                  in-person or video interview
                </li>
                <li>Our approved video platform is Microsoft Teams.</li>
                <li>
                  During the application phase, POWER will never ask for sensitive personal data or
                  information.
                </li>
                <li>
                  POWER will <strong>never</strong> ask you for money at any point.
                </li>
                <li>
                  Any payments we make to you will be from an official account bearing the name
                  POWER Engineers.
                </li>
                <li>
                  Learn more about POWER’s hiring process{' '}
                  <a href="https://www.powereng.com/library/ive-applied-now-what">here</a>.
                </li>
              </ul>
            </StyledBox>
          </Layout>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
